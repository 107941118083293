<template>
  <div v-if="props.direct != 0" class="arrow-container flex items-center text-12" :class="directStr">
    <i :class="['iconfont', 'icon-shangsheng', directStr, `text-${props.size}`]" :style="{ fontSize: `${props.size}px` }" />
  </div>
</template>

<script setup>
const props = defineProps({
  direct: {
    type: Number,
    default: 0
  },
  size: {
    type: Number,
    default: 10
  }
})
let directStr = ref('')
if (props.direct > 0) {
  directStr = 'up'
}
if (props.direct < 0) {
  directStr = 'down'
}
if (props.direct == 0) {
  directStr = 'none'
}
</script>

<style lang="scss" scoped>
@import url(../../assets/styles/_color.scss);
.arrow-container {
  display: inline-block;
  &.up {
    color: $text-red-color;
  }
  &.down {
    color: $text-green-color;
    transform: rotate(180deg);
  }
}
</style>
